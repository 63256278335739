<template>
  <div class="score-chart__wrapper">
    <div
      v-if="dividePart"
      class="score-chart__control"
    >
      <GlSelectButton
        :disabled="loading"
        grey
        :options="optionsPart"
        :value="activePart"
        @input="partChange"
      />
    </div>
    <div class="score-chart__top-wrapper">
      <div
        v-if="
          (clusterData.owner || clusterData.type?.score) &&
            (activePart.value === 'score' || !dividePart)
        "
        class="score-chart__top"
      >
        <div class="score-chart__hint-text">
          Profile Risk
        </div>
        <div class="flex justify-center">
          <GlBadgeInfo
            :entity-id="clusterData.entityId"
            :text="clusterData.owner"
            :value="clusterData.type?.score ?? -1"
          />
        </div>

        <div class="score-chart__middle-svg">
          <RiskArrowTopIcon :score="clusterData.type?.score ?? -1" />
        </div>
      </div>
    </div>

    <div class="score-chart__middle-wrapper">
      <div
        v-show="activePart.value === 'sof' || !dividePart"
        class="score-chart__middle-left-wrapper"
      >
        <div
          v-if="incomingSourcesResult.length && !loading"
          class="score-chart__middle-left"
        >
          <div class="score-chart__hint-text">
            Source of Funds
          </div>
          <GlBadgeInfo
            v-for="(el, i) in incomingSourcesResult"
            :key="i"
            :text="el.funds?.name"
            :value="el?.funds?.default ? -1 : el?.funds?.score ?? -1"
          />
        </div>
        <div v-else-if="dividePart">
          No data
        </div>
      </div>
      <div
        v-show="activePart.value === 'score' || !dividePart"
        class="score-chart__middle-center-wrapper"
      >
        <div class="score-chart__side-svg">
          <RiskArrowLeftIcon
            v-if="incomingSourcesResult.length && !loading"
            :score="incomingSourcesResult[0]?.funds?.score ?? -1"
          />
        </div>

        <div class="score-chart-container">
          <GlCircleChart
            :has-funds-data="hasFundsData"
            :loading="loading || false"
            :score="score"
          />
        </div>

        <div class="score-chart__side-svg">
          <RiskArrowRightIcon
            v-if="outgoingSourcesResult.length && !loading"
            :score="outgoingSourcesResult[0]?.funds?.score ?? -1"
          />
        </div>
      </div>

      <div
        v-show="activePart.value === 'uof' || !dividePart"
        class="score-chart__middle-right-wrapper"
      >
        <div
          v-if="outgoingSourcesResult.length && !loading"
          class="score-chart__middle-right"
        >
          <div class="score-chart__hint-text">
            Use of Funds
          </div>
          <GlBadgeInfo
            v-for="(el, i) in outgoingSourcesResult"
            :key="i"
            :text="el.funds?.name"
            :value="el?.funds?.default ? -1 : el?.funds?.score ?? -1"
          />
        </div>
        <div v-else-if="dividePart">
          No data
        </div>
      </div>
    </div>

    <div class="score-chart__bottom-wrapper">
      <div
        v-if="
          (ownerData.owner || ownerData.type?.score) &&
            (activePart.value === 'score' || !dividePart)
        "
        class="score-chart__bottom"
      >
        <div class="score-chart__middle-svg">
          <RiskArrowBottomIcon :score="ownerData.type?.score ?? -1" />
        </div>
        <div class="score-chart__hint-text">
          Address Risk
        </div>
        <div class="flex justify-center">
          <GlBadgeInfo
            :entity-id="ownerData.entityId"
            :text="ownerData.owner"
            :value="ownerData.type?.score ?? -1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlCircleChart from '@/components/charts/gl-circle-chart'
import RiskArrowTopIcon from '@/assets/svg/risk/RiskArrowTopIcon'
import RiskArrowBottomIcon from '@/assets/svg/risk/RiskArrowBottomIcon'
import RiskArrowRightIcon from '@/assets/svg/risk/RiskArrowRightIcon'
import RiskArrowLeftIcon from '@/assets/svg/risk/RiskArrowLeftIcon'
import GlBadgeInfo from '@/components/gl-badge-info'
import GlSelectButton from '@/components/gl-select-button'
// Utils
import appConfig from '@/utils/appConfig'
import { calculateRiskLevel } from '@/utils/risk-formatter'
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlCircleChart,
    RiskArrowTopIcon,
    RiskArrowBottomIcon,
    RiskArrowRightIcon,
    RiskArrowLeftIcon,
    GlBadgeInfo,
    GlSelectButton,
  },
  mixins: [deviceWidthMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    score: {
      type: [Number, String],
      default: '',
    },
    hasFundsData: {
      type: Boolean,
      default: true,
    },
    profile: {
      type: Object,
      default: () => {},
    },
    incomingSources: {
      type: Array,
      default: () => [],
    },
    outgoingSources: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      optionsPart: [
        { name: 'SoF', value: 'sof' },
        { name: 'Score', value: 'score' },
        { name: 'Uof', value: 'uof' },
      ],
      activePart: { name: 'Score', value: 'score' },
    }
  },
  computed: {
    dividePart() {
      return this.windowWidth <= 950
    },
    ownerData() {
      return this.profile?.ownerData || {}
    },
    clusterData() {
      return this.profile?.clusterData || {}
    },
    incomingSourcesResult() {
      return this.filterSources(this.incomingSources) || []
    },
    outgoingSourcesResult() {
      return this.filterSources(this.outgoingSources) || []
    },
    lowRisk() {
      return appConfig.VUE_APP_RISK_LOW_THRESHOLD
    },
    mediumRisk() {
      return appConfig.VUE_APP_RISK_MEDIUM_THRESHOLD
    },
    highRisk() {
      return appConfig.VUE_APP_RISK_HIGH_THRESHOLD
    },
  },
  methods: {
    calculateRiskLevel,
    filterSources(sources) {
      const groupedSources = this.groupSources(sources)

      if (groupedSources[this.calculateRiskLevel(this.highRisk)]?.length) {
        return groupedSources[this.calculateRiskLevel(this.highRisk)].slice(
          0,
          5,
        )
      }
      if (groupedSources[this.calculateRiskLevel(this.mediumRisk)]?.length) {
        return groupedSources[this.calculateRiskLevel(this.mediumRisk)].slice(
          0,
          5,
        )
      }
      if (groupedSources[this.calculateRiskLevel(this.lowRisk)]?.length) {
        return groupedSources[this.calculateRiskLevel(this.lowRisk)].slice(0, 5)
      }
    },
    groupSources(sources) {
      return sources.reduce((acc, item) => {
        const score = item.funds?.score ?? null
        let group

        group = this.calculateRiskLevel(score)

        if (!acc[group]) {
          acc[group] = []
        }

        acc[group].push(item)

        return acc
      }, {})
    },
    partChange(val) {
      this.activePart = val
    },
  },
}
</script>
<style>
.score-chart__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.score-chart__middle-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.score-chart__middle-left-wrapper,
.score-chart__middle-right-wrapper {
  min-width: calc(27% - 32px);
  max-width: calc(27% - 32px);
}

.score-chart__middle-left,
.score-chart__middle-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  height: 280px;
}

.score-chart__middle-left .gl-badge-info-wrapper,
.score-chart__middle-left .score-chart__hint-text {
  display: flex;
  justify-content: flex-end;
}

.score-chart__middle-right .gl-badge-info-wrapper {
  display: flex;
}
.score-chart__middle-right .gl-badge-info {
  flex-direction: row-reverse;
}

.score-chart__middle-center-wrapper {
  width: 46%;
  max-width: 590px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.score-chart__top-wrapper,
.score-chart__bottom-wrapper {
  display: flex;
  justify-content: center;
  min-height: 40px;
}

.score-chart__top,
.score-chart__bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  position: relative;
  z-index: 1;
}

.score-chart__bottom .score-chart__hint-text,
.score-chart__top .score-chart__hint-text {
  margin-bottom: 8px;
}

.score-chart__hint-text {
  color: var(--Accent-Dark-Grey);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.score-chart__middle-svg {
  height: 36px;
  width: 102px;
}
.score-chart__side-svg {
  width: 84px;
  min-width: 36px;
  height: 240px;
}

.score-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 280px;
}

.score-chart__control {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
}
.score-chart__control .gl-select-button__item {
  min-width: 100px;
}

@media (max-width: 1300px) {
  .score-chart-container,
  .score-chart-container .gl-circle-chart__wrapper {
    width: 256px;
    height: 256px;
  }
  .score-chart__middle-left,
  .score-chart__middle-right {
    height: 256px;
  }
}

@media (max-width: 1100px) {
  .score-chart-container,
  .score-chart-container .gl-circle-chart__wrapper {
    width: 228px;
    height: 228px;
  }
  .score-chart__middle-left,
  .score-chart__middle-right {
    height: auto;
  }

  .gl-circle-chart__score {
    font-size: 42px;
    line-height: 52px;
  }

  .gl-circle-chart__text {
    font-size: 20px;
    line-height: 36px;
  }

  .score-chart__side-svg {
    width: 36px;
  }
  .score-chart__middle-left-wrapper,
  .score-chart__middle-right-wrapper {
    min-width: calc(33% - 16px);
    max-width: calc(33% - 16px);
  }
  .score-chart__middle-center-wrapper {
    width: 34%;
  }
}

@media (max-width: 950px) {
  .score-chart__middle-left-wrapper,
  .score-chart__middle-right-wrapper {
    max-width: 320px;
  }
  .score-chart__middle-center-wrapper {
    width: 100%;
    max-width: 320px;
  }
}
@media (max-width: 767px) {
  .score-chart__control .gl-select-button__item {
    min-width: unset;
  }
  .score-chart__middle-left-wrapper,
  .score-chart__middle-right-wrapper {
    min-width: 100%;
    max-width: 100%;
  }
}
</style>
