var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"flex mb-4 flex-wrap gap-4 t-column"},[_c('div',{staticClass:"flex-2 fullwidth"},[_c('Section',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(" Transaction Information ")])]},proxy:true},{key:"body",fn:function(){return [(!_vm.txData.tx_hash && !_vm.txDataLoading)?_c('div',{staticClass:"mb-4 pa-2"},[_vm._v(" There are not transaction for this hash ")]):_vm._e(),_c('div',{staticClass:"pa-2"},[_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":_vm.isMobile ? false : true,"label":"TX HASH","loading":_vm.txDataLoading,"value":_vm.txData.tx_hash,"word-break-value":""}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":_vm.isMobile ? false : true,"label":"Total amount","loading":_vm.txDataLoading,"rate":_vm.txData
                  && _vm.txData.inputsAmount
                  && _vm.formatByPrice(_vm.txData.inputsAmountPrice),"rate-time":"historical","rate-timestamp":_vm.txData && _vm.txData.inputsAmountPriceTimestamp,"value":_vm.txData
                  && _vm.txData.formattedTotalAmount}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":_vm.isMobile ? false : true,"label":"Block","loading":_vm.txDataLoading,"value":_vm.txData
                  && _vm.txData.blockHeight
                  && _vm.toComaSeparate(String(_vm.txData.blockHeight))}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":_vm.isMobile ? false : true,"label":"Total inputs","loading":_vm.txDataLoading,"value":_vm.txData
                  && _vm.toComaSeparate(String(_vm.txData.totalInputs))}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":_vm.isMobile ? false : true,"label":"Total outputs","loading":_vm.txDataLoading,"value":_vm.txData
                  && _vm.toComaSeparate(String(_vm.txData.totalOutputs))}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":_vm.isMobile ? false : true,"label":"Timestamp","loading":_vm.txDataLoading,"value":_vm.txData
                  && _vm.txData.timestamp
                  && _vm.formatDate(_vm.txData.timestamp * 1000, 'dd.MM.yyyy HH:mm')}})],1)]},proxy:true}])})],1)]),_c('SectionBlock',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" GL-Score ")]},proxy:true},{key:"body",fn:function(){return [_c('ScoreChartWrapper',{attrs:{"incoming-sources":_vm.allDataSource.incoming,"loading":_vm.calculationLoading,"outgoing-sources":_vm.allDataSource.outgoing,"score":_vm.totalFunds}})]},proxy:true}])}),_c('SectionBlock',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Risk Exposure ")]},proxy:true},{key:"body",fn:function(){return [(_vm.appConfig.VUE_APP_SCORE_CHART_DONUT)?[_c('div',{staticClass:"flex mb-4 flex-wrap pl-2 pr-2 m-column gap-4"},[_c('div',{staticClass:"flex-1 m-fullwidth"},[_c('PieDataList',{attrs:{"data":_vm.allDataSource.incoming,"loading":_vm.calculationLoading,"title":"By Type","track-by-label":"funds.type","track-by-label-support":"funds.name"}})],1),_c('div',{staticClass:"flex-1 m-fullwidth"},[_c('PieDataList',{attrs:{"data":_vm.allDataSourceByOwner.incoming,"loading":_vm.calculationLoading,"title":"By Owner","track-by-label":"owner"}})],1)])]:[_c('ReportBarsWrapper',{attrs:{"all-data-source":_vm.allDataSource,"all-data-source-by-owner":_vm.allDataSourceByOwner,"loading":_vm.calculationLoading,"pure-sources":_vm.pureSources}})]]},proxy:true}])})],1),(_vm.appConfig.VUE_APP_SCORE_CHART_DONUT)?_c('div',{staticClass:"report-block-wrap"},[(_vm.calculationLoading)?_c('div',{staticClass:"ma-3 bold fs-18"},[_vm._v(" Loading... ")]):_c('div',[(!_vm.riskySourcesResult.length && !_vm.unknownSourcesResult.length &&
          !_vm.knownSourcesResult.length)?_c('div',{staticClass:"ma-3 bold fs-18"},[(_vm.getActiveExposurePart.value == 'incoming')?_c('div',[_vm._v(" "+_vm._s(Object.keys(_vm.getActiveTypeEntityItem).length ? 'There is no sources information available with the chosen type or owner. Please select a different option to view the sources of funds.' : 'There is no sources information available.')+" ")]):_vm._e(),(_vm.getActiveExposurePart.value == 'outgoing')?_c('div',[_vm._v(" "+_vm._s(Object.keys(_vm.getActiveTypeEntityItem).length ? 'There is no destination information available with the chosen type or owner. Please select a different option to view the destinations of funds.' : 'There is no destination information available.')+" ")]):_vm._e()]):_vm._e(),(_vm.riskySourcesResult.length > 0)?_c('txRiskyTable',{staticClass:"mb-5",attrs:{"data":_vm.riskySourcesResult,"has-only-one-source":_vm.sourceConfigsByDirection[_vm.activeExposurePart.value]?.hasOnlyOneSource}}):_vm._e(),(_vm.unknownSourcesResult.length > 0)?_c('txUnknownTable',{staticClass:"mb-5",attrs:{"data":_vm.unknownSourcesResult,"has-only-one-source":_vm.sourceConfigsByDirection[_vm.activeExposurePart.value]?.hasOnlyOneSource}}):_vm._e(),(_vm.knownSourcesResult.length > 0)?_c('txKnownTable',{attrs:{"data":_vm.knownSourcesResult,"has-only-one-source":_vm.sourceConfigsByDirection[_vm.activeExposurePart.value]?.hasOnlyOneSource}}):_vm._e()],1)]):_c('ReportTablesWrapper',{attrs:{"currencies-by-direction":_vm.currenciesByDirection,"loading":_vm.calculationLoading,"pure-sources":_vm.pureSources,"report-type":"tx"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }